<template>
	<md-field class="input" :class="[{ flash: flash }, label ? '' : 'no-padding']" :md-toggle-password="false">
		<label v-if="label">{{ label }}</label>
		<md-input v-model="input" :placeholder="placeholder" :type="type" :disabled="disabled" @blur="emitBlur" />
	</md-field>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: ''
		},
		label: String,
		disabled: {
			type: Boolean,
			default: false
		},
		value: {
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		flash: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			input: this.value
		}
	},
	methods: {
		emitBlur() {
			this.$emit('blur', this.input)
		}
	},
	watch: {
		input() {
			this.$emit('input', this.input)
		},
		value() {
			this.input = this.value
		}
	}
}
</script>
<style lang="scss" scoped>
@import '@/styles/vars.scss';

// Keyframes for flashing animation
@keyframes flashBorder {
  0% {
    border-color: red;
  }
  25% {
    border-color: transparent;
  }
  50% {
    border-color: red;
  }
  75% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}

.input {
  font-family: $font-family-main;
  transition: border-color 0.2s ease;
}

.input.flash {
  border-color: red;
  animation: flashBorder 1s ease-in-out 1;
  background-color: rgba(255, 0, 0, 0.1);
}

.no-padding {
  padding: 0;
}
</style>
