<template>
  <div v-if="data && data.length" class="dialog">
    <div v-if="searchable" class="search-bar">
      <Input v-model="search" type="text" placeholder="search" />
      <span class="close-btn" @click="$emit('close')">x</span>
    </div>
    <ul>
      <li
        class="item"
        v-for="(item, idx) in [fieldName ? { [fieldName]: 'All', id: 'All'} : 'All' , ...itemsToShow]"
        :key="idx"
      >
        <Checkbox @input="handleInput(item)" :value="isSelected(item)" />
        <span>{{fieldName ? item[fieldName] : item }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Checkbox from "@/modules/common/components/Checkbox";
import Input from "@/modules/common/components/Input";
export default {
  props: {
    searchable: { type: Boolean, default: true },
    data: { type: Array, reqired: true },
    initialSelected: { type: Array, reqired: false },
    fieldName: { type: String, default: "" },
    idFieldName: { type: String, required: false }
  },
  data() {
    return {
      search: "",
      selected: [],
      isAllActive:
        this.initialSelected && this.initialSelected.length === this.data.length ? true : false
    };
  },
  created() {
    if (this.initialSelected) this.selected = this.initialSelected.slice();
    // this.$emit("selected", this.selected);
  },
  computed: {
    itemsToShow() {
      if (!this.search) return this.data;
      return this.data.filter(item => {
        const searchStr = this.search.toLowerCase();
        if (this.fieldName) return item[this.fieldName].toLowerCase().includes(searchStr);
        else return item.toLowerCase().includes(searchStr);
      });
    }
  },
  methods: {
    getIsNaN(val) {
      return isNaN(val)
    },
    isSelected(item) {
      const { isAllActive, selected, idFieldName } = this;
      if (item === "All" || item.id === "All") return isAllActive;
      return selected.some(a => {
        return idFieldName ? a[idFieldName] === item[idFieldName] : a === item;
      });
    },
    handleInput(item) {
      const { fieldName, idFieldName } = this;
      if ((item === "All" || item.id === "All") && this.isAllActive) {
        this.selected = [];
        this.isAllActive = false;
      } else if ((item === "All" || item.id === "All") && !this.isAllActive) {
        this.selected = this.data;
        this.isAllActive = true;
      } else {
        const itemIdx = this.selected.findIndex(selected => {
          if (idFieldName) return selected[idFieldName] === item[idFieldName];
          else return selected === item;
        });
        if (itemIdx >= 0) {
          this.selected.splice(itemIdx, 1);
          this.isAllActive = false;
        } else {
          this.selected.push(item);
          if (this.selected.length === this.data.length)
            this.isAllActive = true;
        }
      }
      this.$emit("selected", this.selected);
    }
  },
  components: {
    Checkbox,
    Input
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";
.dialog {
  box-shadow: 0px 0px 1px $grey;
  background-color: $white;
  z-index: 11;
  .search-bar {
    display: flex;
    align-content: center;
    padding: 4px 0 9px 0;
    padding-inline-start: 14px;
    height: 45px;
    border-bottom: 1px solid $grey-border-color;
    width: 100%;
    .close-btn {
      cursor: pointer;
      margin-top: 10px;
      margin-inline-end: 10px;
    }
  }
  ul {
    padding-inline-start: 14px;
    padding-bottom: 10px;
    max-height: 200px;
    overflow-y: scroll;
    @include scroll;
    .item {
      margin-top: 9px;
      display: grid;
      grid-template-columns: 30px 1fr;
      span {
        margin-top: 3px;
      }
    }
  }
}
</style>